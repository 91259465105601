import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const AmazonCallbackPage = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    // Obtendo os parâmetros da URL (o 'code')
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');
  
    // Obtendo o userId do localStorage
    const userId = localStorage.getItem('userId');
  
    if (!code) {
      setError('Código de autorização não encontrado.');
      setLoading(false);
      return;
    }
    
    console.log(`/callback-amazon?code=${code}&state=${userId}`);

    // Fazer a requisição ao backend para trocar o código pelo token
    fetch(`https://vmproj.onrender.com/callback-amazon?code=${code}&state=${userId}`)
        .then(response => response.json())
        .then(data => {
            if (data.success) {
            localStorage.setItem('authTokenAmazon', data.token);
            navigate('/dashboard');
            } else {
            setError(data.message || 'Falha ao obter o token.');
            }
            setLoading(false);
        })
        .catch(err => {
            setError('Erro ao comunicar com o servidor.');
            console.error(err); // Log para detalhes do erro
            setLoading(false);
        });

  }, [location, navigate]);

  return (
    <div className="callback-page">
      {loading ? (
        <h2>Processando...</h2>
      ) : error ? (
        <div className="error-message">
          <h2>{error}</h2>
        </div>
      ) : (
        <h2>Autenticação bem-sucedida! Redirecionando...</h2>
      )}
    </div>
  );
};

export default AmazonCallbackPage;
