import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './ResetPassword.css'; // Importar o CSS da modal

const ResetPassword = () => {
  const [token, setToken] = useState('');
  const [email, setEmail] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição da modal
  const navigate = useNavigate(); // Para redirecionar o usuário

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
  
    try {
      const response = await axios.post('https://vmproj.onrender.com/reset-password', {
        token,
        email,
        newPassword
      });
  
      if (response.data.success) {
        setMessage('Senha redefinida com sucesso!');
        setShowModal(true); // Mostra a modal de sucesso
        navigate('/login')
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage('Erro ao tentar redefinir a senha. Tente novamente.');
      console.error('Erro na redefinição de senha:', error);
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    navigate('/login'); // Redireciona para a página de login após fechar a modal
  };

  return (
    <div className="reset-password-container">
      <h2>Redefinição de Senha</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Email:</label>
          <input 
            type="email" 
            value={email}
            onChange={(e) => setEmail(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label>Token:</label>
          <input 
            type="text" 
            value={token}
            onChange={(e) => setToken(e.target.value)} 
            required 
          />
        </div>
        <div>
          <label>Nova Senha:</label>
          <input 
            type="password" 
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)} 
            required 
          />
        </div>
        <button type="submit" disabled={loading}>
          {loading ? 'Redefinindo...' : 'Redefinir Senha'}
        </button>
      </form>
      {message && <p className="message">{message}</p>}

      {/* Modal para confirmação de redefinição de senha */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Sucesso!</h3>
            <p>Sua senha foi redefinida com sucesso. Você será redirecionado para a página de login.</p>
            <button onClick={closeModal}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ResetPassword;
