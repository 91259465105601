import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './PriConfig.css'; 

const PriConfig = () => {
  const [vlImport, setVlImport] = useState('10000-50000'); // Valor padrão selecionado
  const [aliquota, setAliquota] = useState(0); // Valor padrão
  const [ads, setAds] = useState(3); // Valor padrão
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(true); // Estado da modal
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId'); // Obtém o ID do usuário logado

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const response = await axios.get(`https://vmproj.onrender.com/verificar-configa/${userId}`);
        if (response.data.success) {
          const config = response.data.config;
          setVlImport(config.vlimport || '10000-50000'); // Usa o valor padrão se não houver configuração
          setAliquota(config.aliquota || 0);
          setAds(config.ads || 3);
        }
      } catch (error) {
        console.error('Erro ao buscar configurações', error);
      }
    };

    fetchConfig();
  }, [userId]);

  // Função para calcular a média do intervalo selecionado
  const calculateAverage = (range) => {
    const [min, max] = range.split('-').map(Number);
    return (min + max) / 2;
  };

  // Formatar o valor para exibição como dinheiro (R$)
  const formatMoney = (range) => {
    const [min, max] = range.split('-').map(Number);
    return `R$ ${min.toLocaleString()} - R$ ${max.toLocaleString()}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    // Calcula a média do intervalo selecionado
    const vlImportAverage = calculateAverage(vlImport);
    console.log('Enviando os dados:', { vlImportAverage, aliquota, ads, fkuser: userId }); // Log dos dados

    try {
      const response = await axios.post('https://vmproj.onrender.com/save-config', {
        vlImport: vlImportAverage,  // Envia a média calculada ao invés do intervalo
        aliquota,
        ads,
        fkuser: userId
      });

      if (response.data.success) {
        setMessage('Configurações salvas com sucesso!');
        navigate('/dashboard');  // Redireciona para o dashboard após salvar
      } else {
        setMessage('Falha ao salvar as configurações. Tente novamente.');
      }
    } catch (error) {
      setMessage('Erro ao salvar as configurações. Tente novamente.');
      console.error('Erro ao salvar as configurações:', error);
    } finally {
      setLoading(false);
    }
  };

  // Função para fechar a modal
  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="priconfig-container">
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <h2>Instruções</h2>
            <p>Preencha os campos com as informações de faturamento, alíquota de IR e porcentagem de anúncios. Certifique-se de salvar suas configurações antes de sair.</p>
            <button onClick={closeModal}>Fechar</button>
          </div>
        </div>
      )}

      <h2>Configuração Inicial</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Faturamento mensal(média):</label>
          <select 
            value={vlImport} 
            onChange={(e) => setVlImport(e.target.value)} 
            required
          >
            <option value="100-10000">{formatMoney("100-10000")}</option>
            <option value="10000-50000">{formatMoney("10000-50000")}</option>
            <option value="50000-100000">{formatMoney("50000-100000")}</option>
            <option value="100000-500000">{formatMoney("100000-500000")}</option>
            <option value="500000-1000000">{formatMoney("500000-1000000")}</option>
          </select>
        </div>

        <div>
          <label>Aliquota IR %:</label>
          <input 
            type="number" 
            value={aliquota} 
            onChange={(e) => setAliquota(e.target.value)} 
            step="0.01" 
            min="0" 
            max="100"
            required 
            placeholder="Ex: 10%"
          />
        </div>

        <div>
          <label>Anúncios (ads) %:</label>
          <input 
            type="number" 
            value={ads} 
            onChange={(e) => setAds(e.target.value)} 
            step="0.01" 
            min="0" 
            max="100"
            required 
            placeholder="Ex: 5%"
          />
        </div>

        <button type="submit" disabled={loading}>
          {loading ? 'Salvando...' : 'Salvar Configurações'}
        </button>
      </form>

      {message && <p className="message">{message}</p>}
    </div>
  );
};

export default PriConfig;
