import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom'; // Importar o useNavigate
import styles from './ProductList.module.css'; // Importar como módulo
import SidebarMenu from './componente/SidebarMenu';

const ProductList = () => {
  const [marketplace, setMarketplace] = useState('mercado_livre'); // Marketplace selecionado
  const [produtos, setProdutos] = useState([]); // Lista de produtos
  const [filteredProdutos, setFilteredProdutos] = useState([]); // Lista de produtos filtrados
  const [loading, setLoading] = useState(false); // Estado de carregamento
  const [error, setError] = useState(null); // Estado de erro
  const [searchQuery, setSearchQuery] = useState(''); // Estado para armazenar o texto da pesquisa
  const [sidebarExpanded, setSidebarExpanded] = useState(true); // Estado local para controlar a expansão da sidebar

  const navigate = useNavigate(); // Hook do React Router

  // Função para buscar produtos do marketplace selecionado
  const fetchProdutos = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.get(`https://vmproj.onrender.com/meus-produtosml`, {
        params: { fkuser: localStorage.getItem('userId') } // Enviar o fkuser como parâmetro
      });

      if (response.data.success) {
        setProdutos(response.data.produtos);
        setFilteredProdutos(response.data.produtos); // Inicializa a lista de produtos filtrados com todos os produtos
      } else {
        setError(response.data.error || 'Erro ao buscar produtos');
      }
    } catch (err) {
      setError('Erro ao buscar produtos');
    } finally {
      setLoading(false);
    }
  };

  // Executar fetchProdutos sempre que o marketplace for alterado
  useEffect(() => {
    fetchProdutos();
  }, [marketplace]);

  // Função para lidar com o clique no card do produto
  const handleCardClick = (produtoId) => {
    navigate(`/produto/${produtoId}`, { state: { marketplace } }); // Redirecionar e passar o marketplace
  };

  // Função para filtrar os produtos com base na pesquisa
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    
    // Filtrar os produtos com base no título ou categoria
    const filtered = produtos.filter((produto) =>
      produto.title.toLowerCase().includes(query) || produto.category_id.toLowerCase().includes(query)
    );
    setFilteredProdutos(filtered); // Atualiza os produtos filtrados
  };

  // Função que será passada para SidebarMenu para atualizar o estado da sidebar
  const handleSidebarToggle = (expanded) => {
    setSidebarExpanded(expanded); // Atualiza o estado local da sidebar
  };

  return (
    <div className={`${styles.container} ${sidebarExpanded ? styles.expanded : styles.collapsed}`}>
      <SidebarMenu onSidebarToggle={handleSidebarToggle} /> {/* Passa a função de toggle para a SidebarMenu */}
      
      <h1>Meus Produtos</h1>

      {/* Barra de pesquisa */}
      <input
        type="text"
        className={styles.searchBar}
        value={searchQuery}
        onChange={handleSearchChange}
        placeholder="Pesquisar produtos..."
      />

      
      {/* <select value={marketplace} className="sele" onChange={(e) => setMarketplace(e.target.value)}>
        <option value="mercado_livre">Mercado Livre</option>
        
      </select> */}

      {/* Se estiver carregando, exibir mensagem de carregamento */}
      {loading ? <p>Carregando produtos...</p> : null}

      {/* Se houver erro, exibir mensagem de erro */}
      {error ? <p>{error}</p> : null}

      {/* Renderizar os produtos filtrados */}
      <div className={styles.productList}>
        {filteredProdutos.length > 0 ? (
          filteredProdutos.map((produto) => (
            <div
              key={produto.id}
              className={styles.card}
              onClick={() => handleCardClick(produto.id)} // Adicionar evento de clique
              style={{ cursor: 'pointer' }} // Mostrar que é clicável
            >
              <img src={produto.thumbnail} alt={produto.title} className={styles.image} />
              <h2>{produto.title}</h2>
              <p>Preço: R$ {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produto.price)}</p>
              <p>Categoria: {produto.category_id}</p>
              <p>Disponível: {produto.available_quantity}</p>
              <p>Vendido: {produto.sold_quantity}</p>
            </div>
          ))
        ) : (
          <p>Nenhum produto encontrado</p>
        )}
      </div>
    </div>
  );
};

export default ProductList;
