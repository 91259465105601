import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './Login.css';
import ilustra from './logos/ilustra.png';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false); 
  const [resetLoading, setResetLoading] = useState(false); 
  const [resetMessage, setResetMessage] = useState(''); 
  const [showModal, setShowModal] = useState(false); // Estado para controlar a exibição da modal
  const navigate = useNavigate(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    
    try {
      const response = await axios.post('https://vmproj.onrender.com/login', {
        email,
        password
      });

      if (response.data.success) {
        setMessage('Login bem-sucedido!');
        const userId = response.data.userId;
        localStorage.setItem('userId', userId);
        localStorage.setItem('loginTime', new Date().toISOString());

        // Verifica se existe configuração para o usuário
        const configResponse = await axios.get(`https://vmproj.onrender.com/verificar-configa/${userId}`);
        if (configResponse.data.success) {
          // Se a configuração existir, vá para o dashboard
          navigate('/dashboard'); 
        } else {
          // Se não existir, vá para a página de configuração inicial (priconfig)
          navigate('/dashboard');
        }
      } else {
        setMessage(response.data.message);
      }
    } catch (error) {
      setMessage('Erro ao tentar fazer login. Tente novamente.');
      console.error('Erro no login:', error);
    } finally {
      setLoading(false); 
    }
  };

  const handleForgotPassword = async () => {
    if (!email) {
      setResetMessage('Por favor, insira seu email para redefinir a senha.');
      return;
    }

    setResetLoading(true);
    
    try {
      const response = await axios.post('https://vmproj.onrender.com/forgot-password', { email });
      
      if (response.data.message) {
        setResetMessage(response.data.message);
        setShowModal(true); // Mostra a modal quando o e-mail for enviado com sucesso
        

      }
    } catch (error) {
      setResetMessage('Erro ao solicitar redefinição de senha. Tente novamente.');
      console.error('Erro ao redefinir senha:', error);
    } finally {
      setResetLoading(false);
    }
  };

  const closeModal = () => {
    setShowModal(false);
    setResetMessage('');
    navigate('/rwmn');
  };

  return (
    <div className="login-container">
      <div className="login-image-container">
        <img src={ilustra} alt="Ilustração" />
      </div>

      <div className="login-form-container">
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Email:</label>
            <input 
              type="email" 
              value={email}
              onChange={(e) => setEmail(e.target.value)} 
              required 
            />
          </div>
          <div>
            <label>Senha:</label>
            <input 
              type="password" 
              value={password}
              onChange={(e) => setPassword(e.target.value)} 
              required 
            />
          </div>

          <div className="forgot-password">
            <a href="#" onClick={handleForgotPassword}>
              {resetLoading ? 'Enviando...' : 'Esqueci minha senha'}
            </a>
          </div>

          <button type="submit" disabled={loading}>
            {loading ? 'Carregando...' : 'Login'}
          </button>
        </form>

        {message && <p className="login-message">{message}</p>}
        {resetMessage && <p className="reset-message">{resetMessage}</p>}
      </div>

      {/* Modal para confirmação de envio de email */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal">
            <h3>Email enviado</h3>
            <p>Um email com instruções para redefinir sua senha foi enviado para {email}, verifique a caixa de span.</p>
            <button onClick={closeModal}>Fechar</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Login;
