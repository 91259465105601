import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './OpportunitiesPage.css';

const OpportunitiesPage = () => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://vmproj.onrender.com/mais-vendidos-ml');
        if (response.data.success) {
          console.log(response.data)
          setProducts(response.data.products || []);
        } else {
          setError('Erro ao carregar os dados.');
        }
      } catch (err) {
        setError('Erro ao conectar ao servidor.');
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="opportunities-container">
      <header className="header">
        <h1>Oportunidades de Produtos</h1>
        <p>Descubra os produtos mais vendidos no Mercado Livre.</p>
      </header>
      {loading ? (
        <p className="loading">Carregando...</p>
      ) : error ? (
        <p className="error">{error}</p>
      ) : (
        <div className="products-table">
          {products.length > 0 ? (
            products.map((product, index) => (
              <div key={index} className="product-card">
                <div className="product-info">
                  <h3>{product.nome}</h3>
                  <div className="product-actions">
                    <a href={product.url} target="_blank" rel="noopener noreferrer">
                      Ver Produto
                    </a>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p className="no-data">Nenhum produto encontrado.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default OpportunitiesPage;
