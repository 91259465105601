import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import './ConectorDetalhe.css'; // Arquivo CSS para a estilização
import SidebarMenu from './componente/SidebarMenu'; // Importando o componente SidebarMenu
import axios from 'axios';
import amz from './parceiros/amz.png'
import ml from './parceiros/ml.png'
import sp from './parceiros/sp.png'

const ConectorDetalhe = () => {
    const { id } = useParams(); // Obtemos o ID do marketplace da URL
    const navigate = useNavigate();
    const [isConectado, setIsConectado] = useState(false); // Estado para saber se está conectado
    const userId = localStorage.getItem('userId');
    const fkuser = userId;

    // Função para mapear o ID para o nome do marketplace
    const getMarketplaceInfo = (id) => {
        switch (id) {
            case '1':
                return { nome: 'Amazon', icone: amz, clientId: 'amzn1.application-oa2-client.801c0812fb654ed8b60cb6366354a9fc', redirectUri: 'https://www.marketstream.com.br/callback-amazon' };
            case '2':
                return { nome: 'mercado_livre', icone: ml, clientId: '6694555036774276', redirectUri: 'https://www.marketstream.com.br/callback' };
            case '3':
                return { nome: 'Shopee', icone: sp, clientId: 'YOUR_SHOPEE_CLIENT_ID', redirectUri: 'https://www.marketstream.com.br/callback-shopee' };
            default:
                return { nome: 'Desconhecido', icone: '', clientId: '', redirectUri: '' };
        }
    };

    const conector = getMarketplaceInfo(id);

    // Função para verificar se o token já existe
    const verificarToken = async () => {
        try {
            const response = await axios.get('https://vmproj.onrender.com/verificar-token', {
                params: { marketplace: conector.nome, fkuser }
            });
            setIsConectado(response.data.exists);
        } catch (error) {
            console.error('Erro ao verificar token', error);
        }
    };

    // Função para desconectar (apagar o token)
    const desconectar = async () => {
        try {
            await axios.delete('https://vmproj.onrender.com/desconectar-token', {
                data: { marketplace: conector.nome, fkuser }
            });
            setIsConectado(false);
        } catch (error) {
            console.error('Erro ao desconectar', error);
        }
    };

    // Função para redirecionar para o marketplace e conectar
    const conectar = () => {
        if (!userId) {
            console.error('userId não encontrado no localStorage');
            return;
        }
        if(conector.nome == "Amazon"){
            const url = `https://sellercentral.amazon.com/apps/authorize/consent?application_id=${conector.clientId}&state=${userId}&redirect_uri=https://www.marketstream.com.br/callback-amazon`;
            window.location.href = url;
        }
        else if(conector.nome == "mercado_livre"){
            const clientId = '6694555036774276'; // ID do cliente Mercado Livre
            const redirectUri = 'https://www.marketstream.com.br/callback'; // Callback configurada no app do Mercado Livre
            // O state deve ser o userId salvo no localStorage
            const url = `https://auth.mercadolivre.com.br/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(redirectUri)}&state=${userId}`;
            window.location.href = url;
        }  
        

        // Redireciona o usuário para a URL de autorização
    };

    useEffect(() => {
        verificarToken(); // Verificar se já existe um token na montagem do componente
    }, [id]);

    return (
        <div className="conector-detalhe-page">
            <SidebarMenu></SidebarMenu>
            <h1>Integração com {conector.nome}</h1>
            <div className="conector-detalhe-container">
                <div className="conector-info">
                    <img src={conector.icone} alt={conector.nome} className="conector-icon-detalhe" />
                    <h2>{conector.nome}</h2>
                    <p>Integre sua conta do {conector.nome} com nossa plataforma.</p>
                </div>
                <div className="conector-config">
                    <h3>Configurações</h3>
                    
                    <div className="status-container">
                        <p>Status:</p>
                        {isConectado ? (
                            <span className="status-conectado">Conectado</span>
                        ) : (
                            <span className="status-desconectado">Desconectado</span>
                        )}
                    </div>

                    {isConectado ? (
                        <button className="desconectar-btn" onClick={desconectar}>Desconectar</button>
                    ) : (
                        <button className="conectar-btn" onClick={conectar}>Conectar</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConectorDetalhe;


