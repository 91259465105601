// src/LandingPage.js
import React, { useState, useEffect } from 'react';
import './LandingPage.css'; // Arquivo CSS separado para os estilos
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBox, faReceipt, faCalculator, faChartLine, faLightbulb, faBolt, faEye } from '@fortawesome/free-solid-svg-icons';

// import { motion } from 'framer-motion';
import { motion, AnimatePresence } from 'framer-motion';

import image1 from './sliderin/graph.svg'
import amz from './parceiros/amz.png'
import ml from './parceiros/ml.png'
import sp from './parceiros/sp.png'
import vd from './sliderin/ff2.mp4'
import price from './vanta/price.png'
import esta from './vanta/estatisticas.png'
import caixa from './vanta/caixa-aberta.png'
import trilha1 from './trilha/nn3.png'
import trilha2 from './trilha/preco.png'
import trilha3 from './trilha/graf.png'
import { useNavigate } from 'react-router-dom';
const images = [image1];

const testimonialsData = [
  {
    name: 'Ana Souza',
    business: 'Proprietária da Loja Nova Moda',
    feedback:
      '“O MarketStream revolucionou a maneira como precifico meus produtos. Agora consigo ajustar meus preços rapidamente, me mantendo competitiva no mercado e aumentando minhas margens de lucro!”',
  },
  {
    name: 'Carlos Mendes',
    business: 'CEO da Eletro Tech',
    feedback:
      '“A ferramenta de precificação inteligente do MarketStream me ajudou a acompanhar as tendências do mercado e definir preços mais competitivos, o que resultou em um aumento significativo nas vendas.”',
  },
  {
    name: 'Fernanda Lima',
    business: 'Gerente de Operações da CasaBella',
    feedback:
      '“Com o MarketStream, nossa equipe pode gerenciar os preços dos produtos de maneira muito mais eficiente. A interface é simples de usar e conseguimos sempre estar à frente da concorrência.”',
  },
  {
    name: 'João Almeida',
    business: 'Proprietário da Mega Móveis',
    feedback:
      '“Ter o controle de precificação de todos os meus produtos em um só lugar fez uma grande diferença. O MarketStream facilita a tomada de decisões estratégicas.”',
  },
  {
    name: 'Lucas Pereira',
    business: 'Fundador da FitStore',
    feedback:
      '“Antes do MarketStream, era um desafio acompanhar as flutuações do mercado. Agora, com a ferramenta de precificação inteligente, consigo ajustar os preços em tempo real e otimizar meus lucros.”',
  },
  {
    name: 'Beatriz Carvalho',
    business: 'Diretora da Bella Joias',
    feedback:
      '“O MarketStream nos ajudou a identificar oportunidades de precificação que nunca tínhamos considerado antes. Aumentamos nossos lucros e nossa base de clientes em pouco tempo.”',
  },
  {
    name: 'Ricardo Santos',
    business: 'CEO da Tech Store',
    feedback:
      '“Com o MarketStream, conseguimos automatizar a análise de preços, economizando horas de trabalho manual e garantindo que estamos sempre oferecendo preços competitivos.”',
  },
  {
    name: 'Mariana Ribeiro',
    business: 'Gerente de Marketing da PetAmor',
    feedback:
      '“O MarketStream foi essencial para entendermos o valor do mercado e ajustarmos nossos preços de acordo com as expectativas dos consumidores. Isso gerou uma ótima resposta nas vendas.”',
  },
];

const LandingPage = () => {
  const [menuOpen, setMenuOpen] = useState(false); // Estado para controlar a abertura do menu
  const [isScrolled, setIsScrolled] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentImage, setCurrentImage] = useState(0);
  const [fade, setFade] = useState(false);
  const testimonials = testimonialsData.slice(currentIndex, currentIndex + 3);
  const [isDisabled, setIsDisabled] = useState(true);
  const navigate = useNavigate();


  const handleCheckoutClick = () => {
    navigate('/checkout'); // Redireciona para a página de checkout
  };


  // const getVisibleTestimonials = () => {
  //   return testimonialsData.slice(currentIndex, currentIndex + 3);
  // };

  // const testimonials = getVisibleTestimonials();

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < testimonialsData.length ? prevIndex + 1 : prevIndex
    );
  };

  const handlePrev = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? testimonialsData.length - 1 : prevIndex - 1
    );
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen); // Alterna a visibilidade do menu
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setFade(false); // Inicia a transição de saída
      setTimeout(() => {
        setCurrentImage((prev) => (prev + 1) % images.length);
        setFade(true); // Inicia a transição de entrada
      }, 1000); // Tempo da transição de saída
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setIsScrolled(true);
      } else {
        setIsScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('timeline-container');
      const timeline = document.getElementById('timeline');

      if (section && timeline) {
        const sectionRect = section.getBoundingClientRect();
        const sectionTop = sectionRect.top;
        const sectionBottom = sectionRect.bottom;
        const sectionHeight = sectionRect.height;
        const windowHeight = window.innerHeight;

        const startTrackingPoint = sectionHeight * 0.15;

        if (sectionTop < windowHeight - startTrackingPoint && sectionBottom > startTrackingPoint) {
          const visibleHeight = Math.min(windowHeight - sectionTop, sectionHeight);
          const scrollPercent = (visibleHeight / sectionHeight) * 100;

          timeline.style.background = `linear-gradient(to bottom, #013220 ${scrollPercent}%, #ccc ${scrollPercent}%)`;
        } else if (sectionTop >= windowHeight) {
          timeline.style.background = `linear-gradient(to bottom, #ccc 0%, #ccc 100%)`;
        } else if (sectionBottom <= 0) {
          timeline.style.background = `linear-gradient(to bottom, #013220 100%, #ccc 100%)`;
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleWhatsAppClick = () => {
    const phoneNumber = "5511984437918"; // Inclua o número com o código do país. Ex: 5511999999999
    const message = encodeURIComponent("Olá, gostaria de falar sobre os planos do MarketStream."); // Mensagem padrão
    const whatsappURL = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${message}`;

    window.open(whatsappURL, "_blank"); // Abre o WhatsApp em uma nova aba
  };

  const handleScroll2 = () => {
    const section = document.getElementById('pricing-section'); // Substitua 'mais-info' pelo ID da seção
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const handleScroll3 = () => {
    const section = document.getElementById('testimonials-section'); // Substitua 'mais-info' pelo ID da seção
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  };

  
  
  return (
    <div className="container">
      {/* Cabeçalho */}
      <div className={`header ${isScrolled ? 'scrolled' : ''}`}>
        <h1 className="logo">arketStream</h1>
        <div className="hamburger" onClick={toggleMenu}>&#9776;</div>
        <div className={`nav ${menuOpen ? 'active' : ''}`}>
          <span onClick={handleScroll2}>Planos</span>
          <span onClick={handleScroll3}>Depoimentos</span>
          <span onClick={handleWhatsAppClick}>Contato</span>
          
          {/* Botão de Login */}
          <span 
            className="login-button" 
            onClick={() => window.open('https://front-theta-liart.vercel.app/login', '_blank')} // Substitua pela URL desejada
          >
            Login
          </span>
          
          <div className="social-icons">
            <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
              <i className="fab fa-instagram"></i>
            </a>
          </div>
        </div>
      </div>


      {/* Hero Section */}
      <div className="hero">
        <video autoPlay muted loop className="videoBackground">
          <source src={vd} type="video/mp4" />
          Seu navegador não suporta vídeos HTML5.
        </video>
        <p className="videoCredits">
          <a href="https://www.freepik.com/free-video/rear-view-american-man-employee-observing-stadistics-graphics-computer-monitor-sitting-desk-office_175716#fromView=search&page=1&position=0&uuid=ad5bd09a-ee4b-4881-b6c2-3e9dd0b8e286">
            Vídeo por freepik
          </a>
        </p>
        <div className="heroText">
          <h2 className="heroTitle">MarketStream: o precificador ideal para o seu negócio crescer</h2>
          <p className="heroSubtitle">
            Não perca tempo e dinheiro, esteja à frente dos concorrentes e defina os preços ideais para seus produtos
          </p>
          <div className="heroButtons">
            <button className="primaryButton" onClick={handleWhatsAppClick} >Fale conosco</button>
            <button className="secondaryButton" onClick={handleScroll2}>Saiba Mais</button>
          </div>
        </div>
      </div>

      {/* Seção de Funcionalidades */}
      <div className="features">
        <h3 className="sectionTitle">Seja Nº1 em vendas</h3>

        {/* Container para os itens */}
        <div className="featuresContainer">
          <div className="featureItem">
            <div className="featureIcon">
              {/* Ícone para Precificação Inteligente */}
              <img src={price} alt="Precificação Inteligente" className="iconImage" />
            </div>
            <h4>Precificação inteligente</h4>
            <p className="description">
              Garanta que seus produtos sejam sempre competitivos no mercado com uma ferramenta de precificação eficiente. 
              Nossa calculadora de preços informa os valores de acordo com as tendências de mercado, garantindo que você 
              nunca perca uma oportunidade de maximizar seus lucros enquanto se mantém competitivo. Deixe a inteligência 
              de mercado trabalhar a seu favor.
            </p>
          </div>

          <div className="featureItem">
            <div className="featureIcon">
              {/* Ícone para Análise de Vendas */}
              <img src={esta} alt="Análise de Vendas" className="iconImage" />
            </div>
            <h4>Análise de vendas</h4>
            <p className="description">
              Obtenha uma visão clara e organizada de suas vendas, com dados detalhados sobre o desempenho de cada produto. 
              Nossa análise de vendas permite que você visualize o lucro líquido de cada transação, identifique tendências 
              e tome decisões estratégicas baseadas em dados concretos, tudo de forma simples e acessível.
            </p>
          </div>

          <div className="featureItem">
            <div className="featureIcon">
              {/* Ícone para Controle de Produtos */}
              <img src={caixa} alt="Controle de Produtos" className="iconImage" />
            </div>
            <h4>Controle dos produtos</h4>
            <p className="description">
              Organize e gerencie seus produtos de forma eficiente com nosso sistema de controle. Nossa ferramenta permite 
              que você tenha total controle sobre a gestão de seus produtos, otimizando o processo de venda e facilitando o 
              crescimento do seu negócio com uma interface intuitiva e de fácil navegação.
            </p>
          </div>
        </div>
      </div>





      <div className="testimonials-section" id='testimonials-section'>
      <h2 className="title">Milhares de clientes impulsionaram seus negócios com o MarketStream. Agora é sua vez.</h2>
      <p className="subtitle">Veja as histórias de sucesso de quem já usou o MarketStream.</p>

      <div className="testimonials-container">
        <button className="arrow-left" onClick={handlePrev} disabled={currentIndex === 0}>
          &#8592;
        </button>

        <div className="testimonials-grid">
          <AnimatePresence>
            {testimonials.map((testimonial, index) => (
              <motion.div
                key={index}
                className="testimonial"
                initial={{ opacity: 0, x: -100 }} // Começa invisível e à esquerda
                animate={{ opacity: 1, x: 0 }}     // Anima para a posição original
                exit={{ opacity: 0, x: 100 }}       // Sai para a direita
                transition={{ duration: 0.5 }}      // Duração da animação
              >
                <div className="testimonial-text">
                  <h3>{testimonial.name}</h3>
                  <h4>{testimonial.business}</h4>
                  <p>{testimonial.feedback}</p>
                </div>
              </motion.div>
            ))}
          </AnimatePresence>
        </div>

        <button className="arrow-right" onClick={handleNext} disabled={currentIndex + 3 >= testimonialsData.length}>
          &#8594;
        </button>
      </div>

      <button className="cta-button" onClick={handleCheckoutClick}>Faça como eles</button>
    </div>

      {/* Seção de Categorias */}
      <div className="categorias">
        <h3 className="sectionTitle">Trilha do sucesso</h3>
        <div className="timeline-container" id="timeline-container">
        <div className="timeline" id="timeline"></div>
          <div className="flow-container">
          <div className="flow-item left">
            <h3>Produtos</h3>
            <p>Cadastre seus produtos de forma fácil e defina os valores necessários. Desfrute da inteligência de mercado para os seus produtos.</p>
          </div>
            
            <div className="flow-item right">
              
              <img src={trilha1} alt="Roteiro" className="flow-image" />
            </div>
          </div>

          <div className="flow-container">
            <div className="flow-item left">
              {/* <h3>Conhecimento</h3>
              <p>Informe seu website e deixe o Agente IA aprender tudo sobre sua empresa.</p> */}
              <img src={trilha2} alt="Conhecimento" className="flow-image" />
            </div>
            <div className="flow-item right">
              <h3>Precificação</h3>
              <p>Obtenha a precificação ideal para o seu produto, com base nas variações do mercado, incluindo taxas, impostos e despesas do seu negócio.</p>
            </div>
          </div>

          <div className="flow-container">
          <div className="flow-item left">
            <h3>Vendas</h3>
            <p>Analise suas vendas e tenha os melhores insights para alavancar seus resultados. Esteja sempre à frente dos seus concorrentes com esta poderosa ferramenta.</p>
          </div>
            <div className="flow-item right">
              <img src={trilha3} alt="Conhecimento" className="flow-image" />
              
            </div>
          </div>

          <button className="start-button" onClick={handleCheckoutClick}>Comece Agora</button>
        </div>
        {/* Exibir lista de funcionalidades */}
      </div>

      <div className="parceiros">
        <h3 className="sectionTitle">Seu negócio conectado aos principais canais de vendas e e-commerces do mercado.</h3>
        <div className="logoContainer">
          {/* Lista de logotipos */}
          <img src={amz} alt="Olist" className="logo2" />
          <img src={ml} alt="Shein" className="logo2" />
          <img src={sp} alt="Shopee" className="logo2" />
          
        </div>
        {/* <button className="connectButton">Conecte suas vendas</button> */}
      </div>

      <div className="pricing-section" id="pricing-section">
      <div className="pricing-header">
        <h2>Preços e vantagens que se adequam ao porte do seu negócio.</h2>
      </div>

      <div className="pricing-grid">
        {/* Plano Básico */}
        <div className="pricing-card">
          <h3>Básico</h3>
          <p>Para vendedores iniciantes</p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faBox} /> Cadastro de produtos
            </li>
            <li>
              <FontAwesomeIcon icon={faReceipt} /> Cadastro  despesas
            </li>
            <li>
              <FontAwesomeIcon icon={faCalculator} /> Calculadora Precificadora
            </li>
          </ul>
          <button onClick={handleCheckoutClick}>comece agora</button>
        </div>

        {/* Plano Pro */}
        <div className="pricing-card">
          <h3>Pro</h3>
          <p>Para vendedores mais experientes</p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faBox} /> Cadastro de produtos
            </li>
            <li>
              <FontAwesomeIcon icon={faReceipt} />  Cadastro de despesas
            </li>
            <li>
              <FontAwesomeIcon icon={faCalculator} /> Calculadora Precificadora
            </li>
            <li>
              <FontAwesomeIcon icon={faChartLine} /> Análise de vendas
            </li>
            <li>
              <FontAwesomeIcon icon={faLightbulb} /> Insights de vendas
            </li>
          </ul>
          <button onClick={handleCheckoutClick}>comece agora</button>
        </div>

        {/* Plano Big Vendedores */}
        <div className="pricing-card destaque">
          <h3>Big vendedores</h3>
          <p>Ideal para grandes vendedores, donos de empresa</p>
          <ul>
            <li>
              <FontAwesomeIcon icon={faBox} /> Cadastro de produtos
            </li>
            <li>
              <FontAwesomeIcon icon={faReceipt} />  Cadastro de despesas
            </li>
            <li>
              <FontAwesomeIcon icon={faCalculator} /> Calculadora Precificadora
            </li>
            <li>
              <FontAwesomeIcon icon={faChartLine} /> Análise de vendas
            </li>
            <li>
              <FontAwesomeIcon icon={faLightbulb} /> Insights de vendas
            </li>
            <li>
              <FontAwesomeIcon icon={faBolt} /> Precificação automática
            </li>
            <li>
              <FontAwesomeIcon icon={faEye} /> Monitoramento de concorrentes
            </li>
          </ul>
          <button disabled={isDisabled}>Embreve</button>
        </div>
      </div>
    </div>


      {/* Footer */}
      <div className="footer">
        <p>© 2024 MarketStream - Todos os direitos reservados</p>
      </div>
    </div>
  );
};

export default LandingPage;