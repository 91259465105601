import React, { useState } from 'react';
import './Conectores.css'; // Arquivo CSS para os estilos
import SidebarMenu from './componente/SidebarMenu'; // Importando o componente SidebarMenu
import { useNavigate } from 'react-router-dom'; // Para navegação entre páginas

import amz from './parceiros/amz.png';
import ml from './parceiros/ml.png';
import sp from './parceiros/sp.png';
import emBreve from './parceiros/image.png';

const conectoresData = [
    { id: 1, nome: 'Amazon', descricao: 'Marketplace', icone: amz },
    { id: 2,  nome: 'mercado_livre', descricao: 'Marketplace', icone: ml },
    { id: 3, nome: 'Shopee', descricao: 'Marketplace', icone: sp },
    // { id: 4, nome: 'eBay', descricao: 'Marketplace', icone: emBreve },
    // { id: 5, nome: 'Walmart', descricao: 'Marketplace', icone: emBreve },
    // { id: 6, nome: 'AliExpress', descricao: 'Marketplace', icone: emBreve },
    // Adicione os outros conectores aqui
];

const Conectores = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const navigate = useNavigate(); // Hook para navegação

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleGerenciarClick = (conector) => {
        // Redireciona para a página de gerenciamento com base no id do conector
        navigate(`/conectores/${conector.id}`, { state: { conector } });
    };

    const filteredConectores = conectoresData.filter((conector) =>
        conector.nome.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <div className="conectores-page conectores-container">
            <SidebarMenu />
            <h1 className="h1co">Minhas Integrações</h1>
            <input
                type="text"
                placeholder="Pesquise pelo nome da integração"
                value={searchTerm}
                onChange={handleSearchChange}
                className="search-bar"
            />
            <div className="conectores-list">
                {filteredConectores.map((conector) => (
                    <div key={conector.id} className="conector-card">
                        <div className="conector-header">
                            <img src={conector.icone} alt={conector.nome} className="conector-icon" />
                        </div>
                        <div className="conector-body">
                            <h3>{conector.nome}</h3>
                            <p>{conector.descricao}</p>
                            <button
                                className="gerenciar-btn"
                                onClick={() => handleGerenciarClick(conector)}
                            >
                                gerenciar
                            </button>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Conectores;
