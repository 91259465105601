import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  BarElement,
  CategoryScale,
  LinearScale,
  Tooltip,
  Legend,
} from 'chart.js';
import SidebarMenu from './componente/SidebarMenu';
import './Dashboard.css';

// Registrar os componentes do Chart.js
ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const Dashboard = () => {
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [orderCount, setOrderCount] = useState(0);
  const [accountAge, setAccountAge] = useState(0);
  const [displayedRevenue, setDisplayedRevenue] = useState(0);
  const [displayedOrders, setDisplayedOrders] = useState(0);
  const [weeklySales, setWeeklySales] = useState([0, 0, 0, 0, 0, 0, 0]);

  useEffect(() => {
    const fetchTotalRevenueData = async () => {
      try {
        const response = await axios.get('https://vmproj.onrender.com/vendedor/receita-total', {
          params: {
            fkuser: localStorage.getItem('userId'),
          },
        });

        const receitaTotal = parseFloat(response.data.receita_total) || 0;
        const quantidadePedidos = response.data.quantidade_pedidos || 0;
        const idadeConta = response.data.idade_conta || 0;

        setTotalRevenue(receitaTotal);
        setOrderCount(quantidadePedidos);
        setAccountAge(idadeConta);
      } catch (error) {
        console.error("Erro ao buscar dados de receita total:", error);
      }
    };

    const fetchOrderData = async () => {
      try {
        const fkuser = localStorage.getItem('userId');
        const response = await axios.post('https://vmproj.onrender.com/get-orders7', { fkuser });

        const orders = response.data; // Lista de pedidos retornada pelo endpoint
        
        // Filtra os pedidos da última semana e organiza por dia da semana
        const salesByDay = [0, 0, 0, 0, 0, 0, 0]; // Inicializa com 0 para cada dia

        const today = new Date();
        orders.forEach(order => {
          const orderDate = new Date(order.data);
          const diffDays = Math.floor((today - orderDate) / (1000 * 60 * 60 * 24));
          
          if (diffDays >= 0 && diffDays < 7) {
            const dayIndex = 6 - diffDays; // Conta de trás para frente (últimos 7 dias)
            salesByDay[dayIndex] += 1; // Conta o pedido no respectivo dia
          }
        });

        setWeeklySales(salesByDay);
      } catch (error) {
        console.error("Erro ao buscar dados de pedidos:", error);
      }
    };

    fetchTotalRevenueData();
    fetchOrderData();
  }, []);

  useEffect(() => {
    const animateCount = (targetValue, setter) => {
      let displayedValue = 0;
      const increment = Math.max(targetValue / 100, 1);
      const interval = setInterval(() => {
        displayedValue += increment;
        if (displayedValue >= targetValue) {
          clearInterval(interval);
          setter(targetValue);
        } else {
          setter(Math.floor(displayedValue));
        }
      }, 20);
    };

    if (totalRevenue > 0) animateCount(totalRevenue, setDisplayedRevenue);
    if (orderCount > 0) animateCount(orderCount, setDisplayedOrders);
  }, [totalRevenue, orderCount]);

  const chartData = {
    labels: ['Sex', 'Sáb', 'Dom', 'Seg', 'Ter', 'Qua', 'Qui'],
    datasets: [
      {
        label: 'Produtos Vendidos',
        data: weeklySales,
        backgroundColor: '#16640f', // Cor das barras
        borderColor: '#16640f', // Cor da borda das barras
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          stepSize: 1,
        },
      },
    },
    plugins: {
      tooltip: {
        callbacks: {
          label: (context) => {
            const dayIndex = context.dataIndex;
            const today = new Date();
            const targetDate = new Date();
            targetDate.setDate(today.getDate() - (6 - dayIndex));
            
            const formattedDate = targetDate.toLocaleDateString('pt-BR', {
              weekday: 'short',
              day: 'numeric',
              month: 'numeric',
            });
            
            return `Data: ${formattedDate} - Vendas: ${context.raw}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
  };

  return (
    <div className="dashboard-container">
      <SidebarMenu />
      <h1>Bem vindo, Rafael Santos Gomes! 👋</h1>

      <div className="cards-container">
        <div className="card sales-card">
          <h2>Total de vendas</h2>
          <h3>{displayedOrders}</h3>
          <p>Total de produtos vendidos</p>
        </div>

        <div className="card revenue-card">
          <h2>Receita Total</h2>
          <h3>
            {typeof displayedRevenue === 'number'
              ? displayedRevenue.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
              : 'R$ 0,00'}
          </h3>
          <p>Receita total</p>
        </div>

        <div className="card small-card">
          <h2>Hoje</h2>
          <p>0 produtos vendidos</p>
        </div>

        <div className="card small-card">
          <h2>Contas conectadas</h2>
          <p>1 Total</p>
        </div>

        <div className="card small-card">
          <h2>Tempo de Ativação</h2>
          <p>{accountAge} dias</p>
        </div>
      </div>

      <div className="chart-container">
        <h2>Vendas da Última Semana</h2>
        <Bar data={chartData} options={chartOptions} />
      </div>
    </div>
  );
};

export default Dashboard;
