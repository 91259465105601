import React, { useState } from 'react';
import styles from './SidebarMenu.module.css';
import { FaBars, FaHome, FaListAlt, FaMoneyBill, FaCog, FaLightbulb, FaLifeRing, FaChartLine, FaCrown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';

const SidebarMenu = ({ onSidebarToggle }) => {
  const [menuExpanded, setMenuExpanded] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();

  const handleSettingsClick = () => navigate('/settings');
  const handleProductsClick = () => navigate('/products');
  const handleDashboardClick = () => navigate('/dashboard');
  const toggleSidebar = () => {
    const newMenuState = !menuExpanded;
    setMenuExpanded(newMenuState);
    onSidebarToggle(newMenuState);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen((prev) => !prev);
  };

  return (
    <div>
      <button className={styles.hamburgerButton} onClick={toggleMobileMenu}>
        <FaBars />
      </button>

      <div
        className={`${styles.sidebar} ${menuExpanded ? styles.expanded : styles.collapsed} ${isMobileMenuOpen ? styles.mobileOpen : ''}`}
      >
        <div className={styles.sidebarHeader}>
          <h2>MarketStream</h2>
          <button onClick={toggleSidebar} className={styles.expandButton}>
            {menuExpanded ? '⮜' : '⮞'}
          </button>
        </div>

        <nav className={styles.menu}>
          <ul>
            <li onClick={handleDashboardClick}><FaChartLine /><span>Dashboard</span></li>
            <li onClick={handleProductsClick}><FaListAlt /><span>Produtos</span></li>
            <li><FaCrown /><span>Best-Sellers</span></li>
            <li><FaMoneyBill /><span>Vendas</span></li>
            <li><FaMoneyBill /><span>Finanças</span></li>
          </ul>
        </nav>

        <div className={styles.menuFooter}>
          <ul>
            <li onClick={handleSettingsClick}><FaCog /><span>Configurações</span></li>
            <li><FaLightbulb /><span>Canal de Ideias</span></li>
            <li><FaLifeRing /><span>Suporte</span></li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
