import React, { useState, useEffect } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import axios from 'axios';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import styles from './ProductDetails.module.css';
import SidebarMenu from './componente/SidebarMenu';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const ProductDetails = () => {
  const { id } = useParams();
  const location = useLocation();
  const { marketplace } = location.state;
  const [produto, setProduto] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [custo, setCusto] = useState(0);
  const [imposto, setImposto] = useState(0);
  const [margem, setMargem] = useState(0);
  const [lucro, setLucro] = useState(0);
  const [salesDates, setSalesDates] = useState([]);

  const calcularMargem = () => {
    const totalCustos = parseFloat(custo) + (parseFloat(imposto) / 100 * produto.price) + produto.marketplace_fee_amount + produto.shipping_cost;
    const margemCalculada = ((produto.price - totalCustos) / produto.price) * 100;
    const lucroCalculado = produto.price - totalCustos;

    setMargem(margemCalculada);
    setLucro(lucroCalculado);
  };

  const fetchProductDetails = async () => {
    try {
      const response = await axios.get(`https://vmproj.onrender.com/produto-detalhesml`, {
        params: { itemId: id, fkuser: localStorage.getItem('userId') }
      });

      if (response.data.success) {
        setProduto(response.data.productDetails);
        setSalesDates(response.data.productDetails.sales_dates);
      } else {
        setError('Erro ao buscar detalhes do produto');
      }
    } catch (err) {
      setError('Erro ao buscar detalhes do produto');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProductDetails();
  }, [id]);

  useEffect(() => {
    if (produto) {
      calcularMargem();
    }
  }, [produto, custo, imposto]);

  if (loading) {
    return <p>Carregando detalhes do produto...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  if (!produto) {
    return null;
  }

  // Organizar as datas de vendas e contar vendas por data
  const salesData = salesDates.reduce((acc, date) => {
    const formattedDate = new Date(date).toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    acc[formattedDate] = (acc[formattedDate] || 0) + 1;
    return acc;
  }, {});

  // Garantir que os dados estejam em ordem cronológica
  const orderedDates = Object.keys(salesData).sort((a, b) => new Date(a) - new Date(b));
  const orderedSales = orderedDates.map(date => salesData[date]);

  const chartData = {
    labels: orderedDates,
    datasets: [
      {
        label: 'Quantidade de Vendas',
        data: orderedSales,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 0.2)',
        borderWidth: 2,
        fill: true,
      }
    ]
  };

  return (
    <div className={styles.container}>
      <SidebarMenu />
      <div className={styles.details}>
        <h1>Detalhes do Produto</h1>
        <img src={produto.thumbnail} alt={produto.title} className={styles.productImage} />

        <p><strong>Título:</strong> {produto.title}</p>
        <p><strong>Preço:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produto.price)}</p>
        <p><strong>Tarifa marketplace:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produto.marketplace_fee_amount)}</p>
        <p><strong>Frete:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(produto.shipping_cost)}</p>

        <div className={styles.inputField}>
          <label>Custo:</label>
          <input type="number" value={custo} onChange={(e) => setCusto(e.target.value)} />
        </div>
        <div className={styles.inputField}>
          <label>Imposto (%):</label>
          <input type="number" value={imposto} onChange={(e) => setImposto(e.target.value)} />
        </div>
        <p><strong>Margem:</strong> {margem.toFixed(2)}%</p>
        <p><strong>Lucro:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(lucro.toFixed(2))}</p>
      </div>

      <div className={styles.chartContainer}>
        <h2>Gráfico de Vendas</h2>
        <Line data={chartData} />
      </div>
    </div>
  );
};

export default ProductDetails;
