import React, { useState } from 'react';
import './Settings.css';
import SidebarMenu from './componente/SidebarMenu';
import { useNavigate } from 'react-router-dom';

const settingsData = [
  {
    title: 'Geral',
    subSettings: ['Idioma', 'Fuso Horário', 'Notificações'],
  },
  {
    title: 'Conta',
    subSettings: ['Perfil', 'Segurança', 'Privacidade'],
  },
  {
    title: 'Aparência',
    subSettings: ['Tema', 'Tamanho da Fonte', 'Layout'],
  },
  {
    title: 'Integrações',
    subSettings: ['API', 'Webhooks', 'Conectores'],
  },
];

const Settings = () => {
  const [expandedSetting, setExpandedSetting] = useState(null);
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const navigate = useNavigate();

  const handleConectoresClick = () => {
    navigate('/conectores');
  };

  const toggleSetting = (index) => {
    setExpandedSetting(expandedSetting === index ? null : index);
  };

  const handleSidebarToggle = (isExpanded) => {
    setSidebarExpanded(isExpanded); // Atualiza o estado de expansão da sidebar
  };

  return (
    <div className={`settings-container ${sidebarExpanded ? 'expanded' : 'collapsed'}`}>
      <SidebarMenu onSidebarToggle={handleSidebarToggle} />
      <h1>Configurações</h1>
      <div className="settings-list">
        {settingsData.map((setting, index) => (
          <div key={index} className="setting-item">
            <div
              className="setting-title"
              onClick={() => toggleSetting(index)}
            >
              {setting.title}
            </div>
            {expandedSetting === index && (
              <ul className="sub-settings-list">
                {setting.subSettings.map((subSetting, subIndex) => (
                  <li 
                    key={subIndex}
                    className="sub-setting-item"
                    onClick={subSetting === 'Conectores' ? handleConectoresClick : null}
                  >
                    {subSetting}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default Settings;
