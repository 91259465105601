// src/CheckoutForm.js
import React, { useState, useEffect } from 'react';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import axios from 'axios';
import './CheckoutForm.css'; // Importa o CSS
import logo from './logos/lgm3.png';

const CheckoutForm = ({ onSignupSuccess }) => {
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [phone, setPhone] = useState('');
    const [password, setPassword] = useState('');
    const [plano, setPlano] = useState('');
    const [products, setProducts] = useState([]);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false); // Estado de loading

    const stripe = useStripe();
    const elements = useElements();

    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = async () => {
        try {
            const response = await axios.get('https://vmproj.onrender.com/productsp');
            setProducts(response.data.data);
        } catch (error) {
            console.error('Erro ao buscar produtos:', error);
        }
    };

    // Validação de email
    const validateEmail = (email) => {
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    };

    // Validação de telefone (apenas números, mínimo 10 dígitos)
    const validatePhone = (phone) => {
        const regex = /^\d{10,}$/; // Alterar conforme necessário (10 ou mais dígitos)
        return regex.test(phone);
    };

    // Validação de senha
    const validatePassword = (password) => {
        return password.length >= 6; // Mínimo 6 caracteres
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!stripe || !elements) return;

        // Validações
        if (!validateEmail(email)) {
            setError('Email inválido.');
            return;
        }
        if (!validatePhone(phone)) {
            setError('Telefone inválido. Deve ter pelo menos 10 dígitos.');
            return;
        }
        if (!validatePassword(password)) {
            setError('Senha deve ter pelo menos 6 caracteres.');
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
        });

        if (error) {
            setError('Erro ao processar pagamento');
        } else {
            setLoading(true); // Ativa o estado de loading
            console.log({ email, name, phone, password, plano, paymentMethodId: paymentMethod.id });
            try {
                await axios.post('https://vmproj.onrender.com/registerp', {
                    login: email,
                    senha: password,
                    nome: name,
                    fone: phone,
                    plano,
                    paymentMethodId: paymentMethod.id,
                }, {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });
                alert('Cadastro e pagamento bem-sucedidos!');
                window.location.href = 'https://front-theta-liart.vercel.app';
                onSignupSuccess({ email, name, phone });
                 // Redireciona para a nova URL
            } catch (err) {
                setError('Erro ao registrar ou processar pagamento');
            } finally {
                setLoading(false); // Desativa o estado de loading após a conclusão
            }
        }
    };

    return (
        <div className="combined-form-container">
            <img src={logo} alt="Logo" className="logopa" /> {/* Adicionando o logo */}
            <form onSubmit={handleSubmit} className="combined-form">
                <h2>Cadastro</h2>
                <div className="input-group">
                    <label>Nome Completo *</label>
                    <input
                        type="text"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Email *</label>
                    <input
                        type="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Telefone *</label>
                    <input
                        type="text"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        required
                    />
                </div>
                <div className="input-group">
                    <label>Senha *</label>
                    <input
                        type="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>

                <h2>Checkout</h2>
                <div className="input-group">
                    <label>Plano</label>
                    <select value={plano} onChange={(e) => setPlano(e.target.value)} required>
                        {products.map(product => (
                            product.prices.map(price => (
                                <option key={price.id} value={price.id}>
                                    {product.name} - {price.unit_amount / 100} {price.currency.toUpperCase()} ({price.recurring?.interval || 'One-time'})
                                </option>
                            ))
                        ))}
                    </select>
                </div>
                <div className="input-group">
                    <label>Informações de Pagamento</label>
                    <CardElement />
                </div>
                <button type="submit" disabled={!stripe || loading}>
                    {loading ? 'Processando...' : 'Finalizar Cadastro e Pagamento'}
                </button>
                {error && <p className="error-message">{error}</p>}
            </form>
        </div>
    );
};

export default CheckoutForm;
